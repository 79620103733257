import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.match.js";
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { USER_ROLES, hasRole } from '@oneecosystem/authenticate';
import MainTabs from '../../../components/UI/Shared/MainTabs';
import Resources from './tabs/Resources';
import Compliance from './tabs/Compliance';
import Faqs from './tabs/Faqs';
import ImaTools from './inner/Resources/ImaTools';
import BrandingMaterials from './inner/Resources/BrandingMaterials';
import ProductAndBusinessPresentations from './inner/Resources/ProductAndBusinessPresentations';
import Video from './inner/Resources/Video';
import Webinars from './inner/Resources/Webinars';
import Audit from './inner/Compliance/Audit';
import LegalDocuments from './inner/Compliance/LegalDocuments';
import ImaPolicy from './inner/Compliance/ImaPolicy';
import AccessRequestForm from './inner/Compliance/AccessRequestForm';
import ConsentWithdrawalForm from './inner/Compliance/ConsentWithdrawalForm';
import RegistrationFaqs from './inner/Faqs/Registration';
import LoginAndSettings from './inner/Faqs/LoginAndSettings';
import BonusOpportunities from './inner/Faqs/BonusOpportunities';
import TokensAndSplits from './inner/Faqs/TokensAndSplits';
import Sponsorship from './inner/Faqs/Sponsorship';
import EducationalPackagesAndPayments from './inner/Faqs/EducationalPackagesAndPayments';
import MiningCoinsAndCoinSafe from './inner/Faqs/MiningCoinsAndCoinSafe';
import AccountVerificationAndCompliance from './inner/Faqs/AccountVerificationAndCompliance';
import GeneralAccountInformation from './inner/Faqs/GeneralAccountInformation';
import { jsx as ___EmotionJSX } from "@emotion/react";
var InformationCenterPage = function InformationCenterPage(props) {
  var isAdmin = hasRole(USER_ROLES.ADMINISTRATOR);
  var tabs = [{
    label: 'Resources',
    route: '/information-center/resources'
  }
  // 04.2023: Hide FAQ temprorary, until information is updated
  // {
  //   label: 'Faqs',
  //   route: '/information-center/faqs',
  // },
  ];

  var adminOnlyTabs = {
    label: 'Compliance',
    route: '/information-center/compliance'
  };
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(MainTabs, {
    tabs: isAdmin ? [].concat(tabs, [adminOnlyTabs]) : tabs
  }), ___EmotionJSX("div", {
    className: "vk-container"
  }, ___EmotionJSX(Switch, null, /* Redirect from root URL to /dashboard. */
  ___EmotionJSX(Redirect, {
    exact: true,
    from: "/information-center",
    to: "/information-center/resources"
  }), ___EmotionJSX(Route, {
    path: "".concat(props.match.path, "/resources"),
    exact: true,
    component: Resources
  }), ___EmotionJSX(Route, {
    path: "".concat(props.match.path, "/faqs"),
    exact: true,
    component: Faqs
  }), ___EmotionJSX(Route, {
    path: "".concat(props.match.path, "/resources/ima-tools"),
    component: ImaTools
  }), ___EmotionJSX(Route, {
    path: "".concat(props.match.path, "/resources/branding-materials"),
    component: BrandingMaterials
  }), ___EmotionJSX(Route, {
    path: "".concat(props.match.path, "/resources/video"),
    component: Video
  }), ___EmotionJSX(Route, {
    path: "".concat(props.match.path, "/resources/product-and-business-presentations"),
    component: ProductAndBusinessPresentations
  }), isAdmin && ___EmotionJSX(React.Fragment, null, ___EmotionJSX(Route, {
    path: "".concat(props.match.path, "/compliance/audit"),
    component: Audit
  }), ___EmotionJSX(Route, {
    path: "".concat(props.match.path, "/compliance/legal-documents"),
    component: LegalDocuments
  }), ___EmotionJSX(Route, {
    path: "".concat(props.match.path, "/compliance/ima-policy"),
    component: ImaPolicy
  }), ___EmotionJSX(Route, {
    path: "".concat(props.match.path, "/compliance/access-request-form"),
    component: AccessRequestForm
  }), ___EmotionJSX(Route, {
    path: "".concat(props.match.path, "/compliance/consent-withdrawal-form"),
    component: ConsentWithdrawalForm
  }), ___EmotionJSX(Route, {
    path: "".concat(props.match.path, "/compliance"),
    exact: true,
    component: Compliance
  }), ___EmotionJSX(Route, {
    path: "".concat(props.match.path, "/resources/webinars"),
    component: Webinars
  })), ___EmotionJSX(Route, {
    path: "".concat(props.match.path, "/faqs/registration"),
    component: RegistrationFaqs
  }), ___EmotionJSX(Route, {
    path: "".concat(props.match.path, "/faqs/login-settings"),
    component: LoginAndSettings
  }), ___EmotionJSX(Route, {
    path: "".concat(props.match.path, "/faqs/bonus-opportunities"),
    component: BonusOpportunities
  }), ___EmotionJSX(Route, {
    path: "".concat(props.match.path, "/faqs/tokens-splits"),
    component: TokensAndSplits
  }), ___EmotionJSX(Route, {
    path: "".concat(props.match.path, "/faqs/sponsorship"),
    component: Sponsorship
  }), ___EmotionJSX(Route, {
    path: "".concat(props.match.path, "/faqs/educational-packages-payments"),
    component: EducationalPackagesAndPayments
  }), ___EmotionJSX(Route, {
    path: "".concat(props.match.path, "/faqs/mining-coins-coinsafe"),
    component: MiningCoinsAndCoinSafe
  }), ___EmotionJSX(Route, {
    path: "".concat(props.match.path, "/faqs/account-verification-compliance"),
    component: AccountVerificationAndCompliance
  }), ___EmotionJSX(Route, {
    path: "".concat(props.match.path, "/faqs/general-account-information"),
    component: GeneralAccountInformation
  }), ___EmotionJSX(Redirect, {
    to: "/error"
  }))));
};
export default InformationCenterPage;