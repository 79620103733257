import React from 'react';
import { ReactComponent as PdfLogo } from '../../../../assets/images/help/pdf.svg';
import { jsx as ___EmotionJSX } from "@emotion/react";
export var complianceCardData = [{
  id: 1,
  title: 'Audit',
  url: '/information-center/compliance/audit'
}, {
  id: 2,
  title: 'Legal Documents',
  url: '/information-center/compliance/legal-documents'
}, {
  id: 3,
  title: 'Ima Policy',
  url: '/information-center/compliance/ima-policy'
}, {
  id: 5,
  title: 'Access Request Form',
  url: '/information-center/compliance/access-request-form'
}, {
  id: 6,
  title: 'Consent Withdrawal Form',
  url: '/information-center/compliance/consent-withdrawal-form'
}, {
  id: 7,
  title: 'Terms and Conditions',
  url: '/terms'
}];
export var auditData = [{
  id: 1,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'Audit Report August 2017',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/Audit Report August 2017 view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/Audit Report August 2017 download.pdf'
}, {
  id: 2,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'Audit Report July 2017',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/Audit Report July 2017 view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/Audit Report July 2017 download.pdf'
}, {
  id: 3,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'Audit Report June 2017',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/Audit Report June 2017 view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/Audit Report June 2017 download.pdf'
}, {
  id: 4,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'Audit Report May 2017',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/Audit Report May 2017 view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/Audit Report May 2017 download.pdf'
}, {
  id: 5,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'Audit Report April 2017',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/Audit Report April 2017 view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/Audit Report April 2017 download.pdf'
}, {
  id: 6,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'Audit Report March 2017',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/Audit Report March 2017 view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/Audit Report March 2017 download.pdf'
}, {
  id: 7,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'Audit Report February 2017',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/Audit Report February 2017 view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/Audit Report February 2017 download.pdf'
}, {
  id: 8,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'Audit Report January 2017',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/Audit Report January 2017 view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/Audit Report January 2017 download.pdf'
}, {
  id: 9,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'Audit Report December 2016',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/Audit Report December 2016 view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/Audit Report December 2016 download.pdf'
}, {
  id: 10,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'Audit Report November 2016',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/Audit Report November 2016 view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/Audit Report November 2016 download.pdf'
}, {
  id: 11,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'Audit Report October 2016',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/Audit Report October 2016 view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/Audit Report October 2016 download.pdf'
}, {
  id: 12,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'Audit Report September 2016',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/Audit Report September 2016 view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/Audit Report September 2016 download.pdf'
}, {
  id: 13,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'Audit Report August 2016',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/Audit Report August 2016 view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/Audit Report August 2016 download.pdf'
}, {
  id: 14,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'Audit Report July 2016',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/Audit Report July 2016 view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/Audit Report July 2016 download.pdf'
}, {
  id: 15,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'Audit Report June 2016',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/Audit Report June 2016 view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/Audit Report June 2016 download.pdf'
}, {
  id: 16,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'Audit Report May 2016',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/Audit Report May 2016 view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/Audit Report May 2016 download.pdf'
}, {
  id: 17,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'Audit Report April 2016',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/Audit Report April 2016 view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/Audit Report April 2016 download.pdf'
}, {
  id: 18,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'Audit Report February 2016',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/Audit Report February 2016 view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/Audit Report February 2016 download.pdf'
}, {
  id: 19,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'Audit Report January 2016',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/Audit Report January 2016 view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/Audit Report January 2016 download.pdf'
}, {
  id: 20,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'Audit Report December 2015',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/Audit Report December 2015 view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/Audit Report December 2015 download.pdf'
}, {
  id: 21,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'Audit Report November 2015',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/Audit Report November 2015 view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/Audit Report November 2015 download.pdf'
}, {
  id: 22,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'Audit Report October 2015',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/ Audit Report October 2015 view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/ Audit Report October 2015 download.pdf'
}, {
  id: 23,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'Audit Report September 2015',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/Audit Report September 2015 view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/Audit Report September 2015 download.pdf'
}, {
  id: 24,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'Audit Report August 2015',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/Audit Report August 2015 view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/Audit Report August 2015 download.pdf'
}, {
  id: 25,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'Audit Report July 2015',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/Audit Report July 2015 view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/Audit Report July 2015 download.pdf'
}, {
  id: 26,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'Audit Report June 2015',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/Audit Report June 2015 view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/Audit Report June 2015 download.pdf'
}, {
  id: 27,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'Audit Report May 2015',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/Audit Report May 2015 view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/Audit Report May 2015 download.pdf'
}, {
  id: 28,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'Audit Result May 2015',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/Audit Result May 2015 view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/Audit Result May 2015 download.pdf'
}];
export var legalDocumentsData = [{
  id: 1,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'Legal Opinion For Sweden',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/Legal Opinion For Sweden view.pdf'
}, {
  id: 2,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'OneCoin Legal Opinion Norway August 2016',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/OneCoin Legal Opinion Norway August 2016 view.pdf'
}, {
  id: 3,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'OneCoin Legal Opinion Italy August 2016',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/OneCoin Legal Opinion Italy August 2016 view.pdf'
}, {
  id: 4,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'OneCoin Legal Opinion Sweden July 2016',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/OneCoin Legal Opinion Sweden July 2016 view.pdf'
}, {
  id: 5,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'OneCoin Legal Opinion Germany June 2016',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/OneCoin Legal Opinion Germany June 2016 view.pdf'
}, {
  id: 6,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'OneCoin Legal Opinion Bulgaria November 2015',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/OneCoin Legal Opinion Bulgaria November 2015 view.pdf'
}, {
  id: 7,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'OneCoin Legal Opinion Germany June 2015',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/OneCoin Legal Opinion Germany June 2015 view.pdf'
}, {
  id: 8,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'OneCoin Legal Opinion December 2014',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/OneCoin Legal Opinion December 2014 view.pdf'
}, {
  id: 9,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'OneCoin Legal Opinion September 2014',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/OneCoin Legal Opinion September 2014 view.pdf'
}];
export var imaPolicyData = [{
  id: 1,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'CROSS-RECRUITING POLICY',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/CROSS-RECRUITING POLICY view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/CROSS-RECRUITING POLICY download.pdf'
}, {
  id: 2,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'IMPORTANT ANNOUNCEMENT - CHINESE',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/IMPORTANT ANNOUNCEMENT - CHINESE view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/IMPORTANT ANNOUNCEMENT - CHINESE download.pdf'
}, {
  id: 3,
  image: ___EmotionJSX(PdfLogo, null),
  title: ' IMPORTANT ANNOUNCEMENT - RUSSIAN',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/IMPORTANT ANNOUNCEMENT - RUSSIAN view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/IMPORTANT ANNOUNCEMENT - RUSSIAN download.pdf'
}, {
  id: 4,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'IMPORTANT ANNOUNCEMENT',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/IMPORTANT ANNOUNCEMENT view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/IMPORTANT ANNOUNCEMENT download.pdf'
}];
export var privacyPolicyData = [{
  id: 1,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'Privacy Policy',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/Privacy Policy view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/Privacy Policy download.pdf'
}];
export var accessRequestFormData = [{
  id: 1,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'Access Request Form',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/Access Request Form view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/Access Request Form download.pdf'
}];
export var consentWithdrawalFormData = [{
  id: 1,
  image: ___EmotionJSX(PdfLogo, null),
  title: 'Consent Withdrawal Form',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/Consent Withdrawal Form view.pdf'
}];