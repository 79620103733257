import { RESOURCE_MEDIA_TYPES } from '../resourcesConfig';
export var imaToolsData = [{
  id: 23,
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'OneEcosystem - Bonus Generating Opportunities',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/OneEcosystem Bonus Generating Opportunities.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/OneEcosystem Bonus Generating Opportunities.pdf'
}, {
  id: 24,
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'OneEcosystem - Leadership Program Ranks',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/OneEcosystem - Leadership Program Ranks.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/OneEcosystem - Leadership Program Ranks.pdf'
}];